import uuidv4 from 'uuid/v4'
import moment from 'moment'

let notes = []

// create a new note
const createNote = () => {
  const id = uuidv4()
  const timestamp = moment().valueOf()

  notes.push({
    id: id,
    title: '',
    body: '',
    createdAt: timestamp,
    updatedAt: timestamp
  })
  saveNotes()

  return id
}

// expose notes from module
const getNotes = () => notes

// load notes from storage
const loadNotes = () => {
  const notesJSON = localStorage.getItem('notes')

  try {
    return notesJSON ? JSON.parse(notesJSON) : []
  } catch (e) {
    return []
  }
}

// remove note from list
const removeNote = id => {
  notes = notes.filter(n => n.id !== id)
  saveNotes()
}

// save notes to storage
const saveNotes = () => {
  localStorage.setItem('notes', JSON.stringify(notes))
}

// sort notes 'byEdited', 'byCreated', or 'alphabetical'
const sortNotes = sortBy => {
  if (sortBy === 'byEdited') {
    return notes.sort((a, b) => {
      if (a.updatedAt > b.updatedAt) {
        // a is more recent, a should come first, return -1
        return -1
      } else if (a.updatedAt < b.updatedAt) {
        // a is older than b, b should come first, return 1
        return 1
      } else {
        return 0
      }
    })
  } else if (sortBy === 'byCreated') {
    return notes.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1
      } else if (a.createdAt < b.createdAt) {
        return 1
      } else {
        return 0
      }
    })
  } else if (sortBy === 'alphabetical') {
    return notes.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1
      } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1
      } else {
        return 0
      }
    })
  } else {
    return notes
  }
}

// update a note
const updateNote = (id, updates) => {
  const note = notes.find(note => note.id === id)

  if (!note) {
    return
  }

  if (typeof updates.title === 'string') {
    note.title = updates.title
    note.updatedAt = moment().valueOf()
  }

  if (typeof updates.body === 'string') {
    note.body = updates.body
    note.updatedAt = moment().valueOf()
  }

  saveNotes()
  return note
}

notes = loadNotes()

export { createNote, getNotes, removeNote, sortNotes, updateNote }
