import moment from 'moment'
import { getFilters } from './filters'
import { sortNotes, getNotes } from './notes'

// Generate the last edited message
const generateLastEdited = timestamp => `Last edited ${moment(timestamp).fromNow()}`

// generate DOM structure for a note
const generateNoteDOM = note => {
  const noteEl = document.createElement('a')
  const textEl = document.createElement('a')
  const statusEl = document.createElement('p')

  // set up note title text
  if (note.title.length > 0) {
    textEl.textContent = note.title
  } else {
    textEl.textContent = 'Untitled note'
  }
  textEl.classList.add('list-item__title')
  noteEl.appendChild(textEl)

  // set up the link
  noteEl.setAttribute('href', `/edit.html#${note.id}`)
  noteEl.classList.add('list-item')

  // set up the status message
  statusEl.textContent = generateLastEdited(note.updatedAt)
  statusEl.classList.add('list-item__subtitle')
  noteEl.appendChild(statusEl)

  return noteEl
}

const initializeEditPage = noteId => {
  const noteTitleInput = document.getElementById('note-title')
  const noteBodyTextArea = document.querySelector('#note-body')
  const lastEditedSpan = document.querySelector('#last-edited')
  const notes = getNotes()
  const note = notes.find(note => note.id === noteId)

  if (!note) {
    location.assign('/index.html')
  }

  noteTitleInput.value = note.title
  noteBodyTextArea.value = note.body
  lastEditedSpan.textContent = generateLastEdited(note.updatedAt)
}

// render application notes
const renderNotes = () => {
  const notesEl = document.querySelector('#notes')
  const filters = getFilters()
  const notes = sortNotes(filters.sortBy)
  const filteredNotes = notes.filter(n => n.title.toLowerCase().includes(filters.searchText.toLowerCase()))

  // clear notes
  notesEl.innerHTML = ''

  if (filteredNotes.length > 0) {
    filteredNotes.forEach(note => {
      const noteEl = generateNoteDOM(note)
      notesEl.appendChild(noteEl)
    })
  } else {
    const emptyMessage = document.createElement('p')
    emptyMessage.textContent = 'No notes to show'
    emptyMessage.classList.add('empty-message')
    notesEl.appendChild(emptyMessage)
  }
}

export { generateLastEdited, generateNoteDOM, initializeEditPage, renderNotes }
