// bootstrap the index.html page
import { createNote } from './notes'
import { setFilters } from './filters'
import { renderNotes } from './views'

renderNotes()

// getElementById is over 2x as fast, but querySelector still performs 7,000 operations per ms, so really not an issue
// for querySelector* -- # is id, . is class
// document.getElementById('btn-create-note').addEventListener('click', e => {
document.querySelector('#btn-create-note').addEventListener('click', e => {
  const id = createNote()
  location.assign(`/edit.html#${id}`)
})

// input fires on each key stroke
document.querySelector('#txt-filter-notes').addEventListener('input', e => {
  setFilters({ searchText: e.target.value })
  renderNotes()
})

document.querySelector('#select-filter-by').addEventListener('change', e => {
  setFilters({ sortBy: e.target.value })
  renderNotes()
})

// watch for changes in window storage, fires with each keystroke
window.addEventListener('storage', e => {
  if (e.key === 'notes') {
    renderNotes()
  }
})
